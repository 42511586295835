import { inject, Injectable } from '@angular/core';
import { AdminStore } from '../store/admin/admin.store';
import { UserStore } from '../store/user/user.store';

@Injectable({
  providedIn: 'root',
})
export class PrefetchService {
  private readonly userStore: InstanceType<typeof UserStore> = inject(UserStore);
  private readonly adminStore: InstanceType<typeof AdminStore> = inject(AdminStore);

  public prefetchAdmin() {
    if (!this.userStore.isAdmin()) {
      return;
    }

    if (!this.adminStore.usersLoaded()) {
      this.adminStore.getUsers();
    }
  }
}
