import { signalStore, type, withHooks } from '@ngrx/signals';
import { withEntities } from '@ngrx/signals/entities';
import type { IUser } from '@archery-events/models/user.model';
import { effect, inject, PLATFORM_ID, untracked } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { UserStore } from '../user/user.store';
import { AdminUserService } from '../../services/http/admin-user.service';
import { withCallState } from '@owain/store-features/features/call-state/call-state.feature';
import { withDataService } from '@owain/store-features/features/data-service/data-service-feature';
import { NotificationType } from '@owain/notifier/lib/models/notifications.model';
import { withEntitiesFilter } from '@owain/store-features/features/entities/filter/entites-filter.feature';
import { withSideEffects } from '@owain/store-features/features/side-effects/side-effects.feature';
import { withEntitiesLocalPagination } from '@owain/store-features/features/entities/pagination/entities-local-pagination';

export const AdminStore = signalStore(
  { providedIn: 'root' },
  // withIndexedDB('admin-store-state', ['callstate']),
  withCallState({
    collection: 'user',
  }),
  withEntities({
    entity: type<IUser>(),
    collection: 'user',
  }),
  withEntitiesFilter({
    entity: type<IUser>(),
    collection: 'user',
    defaultFilter: { search: '' },
    filterFn: (entity, filter) =>
      !filter?.search ||
      !!(entity?.email && entity?.email.toLowerCase().includes(filter?.search.toLowerCase())) ||
      !!(entity?.firstname && entity?.firstname.toLowerCase().includes(filter?.search.toLowerCase())) ||
      !!(entity?.insertion && entity?.insertion.toLowerCase().includes(filter?.search.toLowerCase())) ||
      !!(entity?.lastname && entity?.lastname.toLowerCase().includes(filter?.search.toLowerCase())) ||
      !!(entity?.role && entity?.role.toLowerCase().includes(filter?.search.toLowerCase())),
  }),
  // withEntitiesSort({
  //   entity: type<IUser>(),
  //   collection: 'user',
  //   defaultSort: { field: 'id', direction: 'asc' },
  // }),
  withEntitiesLocalPagination({
    entity: type<IUser>(),
    collection: 'user',
    pageSize: 10,
  }),
  withDataService({
    loggerKey: 'AdminStore',
    dataServiceType: AdminUserService,
    collection: 'user',
  }),
  // withEventHandler(store => {
  //   const sideEffectEvent = createEvent(`users.sideEffect`, props<{ key: string; status: string }>());
  //
  //   return [
  //     onEvent(sideEffectEvent, ({ key, status }) => {
  //       if (key === 'get') {
  //         // @ts-ignore
  //         store.sortUsersEntities();
  //       }
  //     }),
  //   ];
  // }),
  withSideEffects({
    collection: 'user',
    add: {
      success: {
        type: NotificationType.SUCCESS,
        message: 'admin.useradded',

        redirect: '/admin/users',
      },
      failure: {
        type: NotificationType.ERROR,
        message: '500error',
      },
    },
    delete: {
      success: {
        type: NotificationType.SUCCESS,
        message: 'admin.userdeleted',

        castEvent: 'ui:close-modal',
      },
      failure: {
        type: NotificationType.ERROR,
        message: '500error',
      },
    },
  }),
  withHooks({
    onInit(store) {
      const platformId: Object = inject(PLATFORM_ID);
      const userStore: InstanceType<typeof UserStore> = inject(UserStore);

      effect(() => {
        const authenticated = userStore.isAuthenticated();
        const admin = userStore.isAdmin();

        untracked(() => {
          if (authenticated && admin) {
            if (isPlatformBrowser(platformId)) {
              if (!store.usersLoaded()) {
                store.getUsers();
              }
            }
          } else {
            store.resetUsers();
          }
        });
      });
    },
  })
);
