import { inject, PLATFORM_ID } from '@angular/core';
import { signalStoreFeature } from '@ngrx/signals';

import { TranslocoService } from '@jsverse/transloco';
import { isPlatformBrowser, Location } from '@angular/common';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@owain/ngx-transloco-router/localize-router.service';
import { EventBusService } from '@owain/eventbus/event-bus.service';
import { ToasterService } from '@owain/notifier/lib/services/toaster.service';
import { ISideEffect } from '@owain/store-features/models/side-effects.model';
import { onEvent, withEventHandler } from '@owain/store-features/features/event-handler/event-handler';
import { createEvent, props } from '@owain/store-features/features/event-handler/event-handler.util';

export function withSideEffects(sideEffect: ISideEffect) {
  return signalStoreFeature(
    withEventHandler(() => {
      const platformId: Object = inject(PLATFORM_ID);
      const router: Router = inject(Router);
      const localizeRouterService: LocalizeRouterService = inject(LocalizeRouterService);
      const toastService: ToasterService = inject(ToasterService);
      const translocoService: TranslocoService = inject(TranslocoService);
      const eventBusService: EventBusService = inject(EventBusService);
      const location: Location = inject(Location);

      const sideEffectEvent = createEvent(
        sideEffect.key ? `${sideEffect.key}.sideEffect` : `${sideEffect.collection}s.sideEffect`,
        props<{
          key: string;
          status: string;
        }>()
      );

      return [
        onEvent(sideEffectEvent, ({ key, status }) => {
          // @ts-ignore
          const child = sideEffect[key];

          if (child) {
            const successOrFailure = child[status];

            if (successOrFailure) {
              if (isPlatformBrowser(platformId) && successOrFailure.type && successOrFailure.message) {
                toastService.notification({
                  alertType: successOrFailure.type,
                  message: translocoService.translate(successOrFailure.message),
                });
              }

              if (successOrFailure.redirect) {
                if (successOrFailure.redirect === 'back') {
                  location.back();
                } else {
                  router.navigateByUrl(<string>localizeRouterService.translateRoute(successOrFailure.redirect));
                }
              }

              if (successOrFailure.castEvent) {
                eventBusService.cast('ui:close-modal');
              }
            }
          }
        }),
      ];
    })
  );
}
