import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseUrlService } from '../base-url.service';
import type { IUser } from '@archery-events/models/user.model';
import { CrudServiceInterface } from '@owain/store-features/models/crud-service.model';

@Injectable({
  providedIn: 'root',
})
export class AdminUserService implements CrudServiceInterface<IUser> {
  private readonly httpClient = inject(HttpClient);
  private readonly baseUrlService: BaseUrlService = inject(BaseUrlService);

  private url = `${this.baseUrlService.getBaseUrl()}/api/user`;

  getEntities(): Observable<IUser[]> {
    return this.httpClient.get<IUser[]>(this.url);
  }

  getEntity(id: number): Observable<IUser> {
    // Not implemented
    return of(<IUser>{});
  }

  addEntity(user: IUser): Observable<IUser> {
    return this.httpClient.post<IUser>(this.url, {
      firstname: user.firstname,
      insertion: user.insertion,
      lastname: user.lastname,
      email: user.email,
      language: user.language,
    });
  }

  updateEntity(user: IUser): Observable<IUser> {
    // Not implemented
    return of(<IUser>{});
  }

  deleteEntity(user: IUser): Observable<IUser> {
    return this.httpClient.delete<IUser>(`${this.url}/${user.id}`);
  }

  checkEmailAvailability(email: string): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.url}/check`, { email: email });
  }
}
