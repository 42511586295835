import { Signal } from '@angular/core';
import { patchState, WritableStateSource } from '@ngrx/signals';
import { capitalize } from '@owain/store-features/utils/util';
import { createEvent, props } from '@owain/store-features/features/event-handler/event-handler.util';

export function getWithEntitiesLocalPaginationKeys(config?: { collection?: string }) {
  const collection = config?.collection;
  const capitalizedProp = collection && capitalize(collection);
  return {
    paginationKey: collection ? `${config.collection}sPagination` : 'entitiesPagination',
    entitiesCurrentPageKey: collection ? `${config.collection}sCurrentPage` : 'entitiesCurrentPage',
    loadEntitiesPageKey: collection ? `load${capitalizedProp}sPage` : 'loadEntitiesPage',
  };
}

export function setCurrentPage(state: Record<string, Signal<unknown>>, paginationKey: string, pageIndex: number) {
  const pagination = state[paginationKey] as Signal<{
    pageSize: number;
    currentPage: number;
  }>;
  patchState(state as WritableStateSource<object>, {
    [paginationKey]: {
      ...pagination(),
      currentPage: pageIndex,
    },
  });
}

export function getWithEntitiesLocalPaginationEvents(config?: { collection?: string }) {
  const collection = config?.collection;
  return {
    entitiesLocalPageChanged: createEvent(`${collection}s.entitiesLocalPageChanged`, props<{ pageIndex: number }>()),
  };
}
